
import "@/assets/styles/NewItemPage.scss";
import { Component, Vue } from "vue-property-decorator";
import StepComponent from "@/shared/components/NewItemComponent/Steps/Steps.vue";

@Component({
  name: "NewItemPage",
  components: {
    StepComponent,
  },
})
export default class NewItemPage extends Vue {}
