import {
  STEP_OPTION_ITEM_CONFIG,
  COMMERCE_OPTION_ITEMS_DESCRIPTION,
  JOB_OPTION_TYPE,
  STEP_TYPE,
} from "@/shared/interface/newItem";
import { TimeUtil } from "./TimeUtils";

export const DEFAULT_PLACEHOLDER = "input summary of this work item";

export const StepUtils = {
  statusConfig: {
    inProgress: {
      icon: "el-icon-edit",
      color: "#59a4c8",
    },
    succeed: {
      icon: "el-icon-check",
      color: "#67c239",
    },
    failed: {
      icon: "el-icon-close",
      color: "#e24e51",
    },
    disabled: {
      icon: "el-icon-check",
      color: "#dcdedf",
    },
    loading: {
      icon: "el-icon-loading",
      color: "#59a4c8",
    },
  },
  stepItems: [
    {
      name: "Choose Product",
      labelPrefix: [],
      type: STEP_TYPE.card,
      show: true,
      valueName: "product",
      valuesArray: [],
      valuesDisabled: [false, false],
      hasNextStepButton: false,
      loading: false,
    },
    {
      name: "Choose Executor",
      labelSurffix: "Execution Engine",
      type: STEP_TYPE.card,
      show: true,
      valueName: "executor",
      valuesArray: [],
      valuesDisabled: [false],
      hasNextStepButton: false,
      loading: false,
    },
    {
      name: "Choose Runbook",
      type: STEP_TYPE.cascader,
      show: true,
      valueName: "contentIdArray",
      valuesArray: [],
      hasNextStepButton: false,
      loading: false,
    },
    {
      name: "Provide Parameters",
      type: STEP_TYPE.form,
      show: false,
      hasNextStepButton: true,
      showNextStepButton: true,
      loading: false,
    },
    {
      name: "Summary",
      type: STEP_TYPE.input,
      loading: false,
      show: false,
      valueName: "summary",
      hasNextStepButton: false,
      placeholder: DEFAULT_PLACEHOLDER,
    },
    {
      name: "Submit",
      type: STEP_TYPE.button,
      show: true,
      icon: "el-icon-check",
      color: "#dcdedf",
      hasNextStepButton: false,
      loading: false,
      disabled: true,
    },
  ],

  /**
   * Transfer the projects and jobs data to the cascader format
   * @param {Array} originData: the projects and jobs array from API
   * @returns {Array} newData: the transformed array
   */

  transformJobs(originData: any[]): Array<any> {
    const newData = originData.map((project) => {
      const children = project.jobs.map((job) => {
        return {
          value: job.id,
          label: job.jobName,
          id: job.id,
          jobName: job.jobName,
          jobRunDeckId: job.jobRunDeckId,
          projectName: project.projectName,
        };
      });
      return {
        value: project.projectId,
        label: project.projectName,
        children: children,
      };
    });
    return newData;
  },

  /**
   * Reads the contents of the file and returns it as a string
   * @param {object} item: file data
   * @returns {string} data: file content
   */
  async getFileContent(item: STEP_OPTION_ITEM_CONFIG): Promise<any> {
    return new Promise<any>((resolve) => {
      const fileData = item.fileList[0];
      const reader = new FileReader();
      reader.readAsBinaryString(fileData.raw);
      reader.onload = async (e: any) => {
        const data = e.target.result;
        resolve(data);
      };
    });
  },

  /**
   * Generate a new ParameterJosn object
   * @param { STEP_OPTION_ITEM_CONFIG } item: the step option object
   * @returns {object} newData: The Parameter object is reassembled
   */
  async createParameterJSON(item: STEP_OPTION_ITEM_CONFIG): Promise<any> {
    let parameterJson = {
      _name: item._name,
      type: item.type,
      value: Array.isArray(item.value)
        ? item.value.join(",")
        : item.value
        ? item.value.toString()
        : "",
      label: item.label,
      hidden: "false",
      _secure: "false",
      fileContent: "",
      isDate: item.isDate,
      description: item?.description,
      allowedValues: item?._values,
      allowedResourceTypes: item?._allowedResourceTypes,
      defaultResourceType: item?._defaultResourceType,
      allowedResourceNum: item?._allowedResourceNum,
      regex: item?._regex,
      secretKey: item?.secretKey,
      argument: item?.argument,
      argumentSign: item?._argument,
      _storagePath: item?._storagePath,
    };
    if (item.type === "multiselect") {
      if (
        item.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.customerResources
      ) {
        parameterJson.value = "[" + item.value.join(",") + "]";
      } else if (
        item.description ===
        COMMERCE_OPTION_ITEMS_DESCRIPTION.customerResourcesNames
      ) {
        const resourceNames = item.value.map(
          (val) => JSON.parse(val).resourceName
        );
        parameterJson.value = JSON.stringify(resourceNames);
      }
    }
    if (item.isDate === "true") {
      Object.assign(parameterJson, {
        dateFormat: item.dateFormat,
      });
      if (
        item.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.datewithTz ||
        item.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.dateRangewithTz
      ) {
        let utcTimestampValue;
        //Date range returns array of utc timestamps
        if (Array.isArray(item.value)) {
          utcTimestampValue = [];
          item.value.forEach((value) => {
            utcTimestampValue.push(
              TimeUtil.getUTCTimeStampForSelectedTimezone(value, item.timeZone)
            );
          });
        } else
          utcTimestampValue = TimeUtil.getUTCTimeStampForSelectedTimezone(
            item.value,
            item.timeZone
          );

        parameterJson.value = JSON.stringify({
          timezone: item.timeZone ? item.timeZone : "",
          utc_timestamp: Array.isArray(utcTimestampValue)
            ? utcTimestampValue.join(",")
            : utcTimestampValue
            ? utcTimestampValue.toString()
            : "",
        });
      }
    }
    if (item.type === "file" && item.fileList.length > 0) {
      parameterJson.value = item.fileList[0].name;
      parameterJson.fileContent = await this.getFileContent(item);
    }

    if (
      item.type === JOB_OPTION_TYPE.cascader &&
      item.description === COMMERCE_OPTION_ITEMS_DESCRIPTION.ccv2DBs
    ) {
      parameterJson.value = item.value[item.value.length - 1];
    }

    if (item.hidden !== undefined) {
      parameterJson = {
        ...parameterJson,
        hidden: item.hidden,
      };
    }
    if (item._secure !== undefined) {
      parameterJson = {
        ...parameterJson,
        secretKey: item._storagePath?.split("/").pop() || item._name, // used by argo
        _secure: item._secure,
      };
    }
    const newData = await parameterJson;
    return newData;
  },
};
