import {
  CUSTOMER_RESOURCE_INFO,
  JOB_OPTION_TYPE,
  STEP_OPTION_ITEM_CONFIG,
} from "@/shared/interface/newItem";
import store from "@/store";
import moment from "moment";
import { TimeUtil } from "./TimeUtils";

export const ParameterAreaUtils = {
  resourceTypeList: [
    {
      label: "AKS",
      value: "Microsoft.ContainerService/managedClusters",
      disabled: false,
    },
    {
      label: "Microsoft.Sql/servers",
      value: "Microsoft.Sql/servers",
      disabled: false,
    },
    {
      label: "Microsoft.Sql/servers/databases",
      value: "Microsoft.Sql/servers/databases",
      disabled: false,
    },
  ],

  /**
   * Handles array items
   * @param {Object} Obj: current handling object
   * @param {Array} defaultValue: the default value array
   * @param {number} index: current data tier (start from 1)
   * @returns void
   */
  handleArrayItems(
    Obj: Record<string, any>,
    defaultValue: any[],
    index: number
  ): void {
    if (Obj.children && Obj.children.length > 0) {
      Obj.children.sort((a, b) => a.alt_sequence - b.alt_sequence);
      Obj.children.forEach((child) => {
        if (
          defaultValue[index - 1] &&
          Obj.default_indicator &&
          child.default_indicator
        )
          defaultValue[index] = child.code;
        this.handleArrayItems(child, defaultValue, index + 1);
      });
    }
  },
  /**
   * transforms customerinfo to label and value array needed for select UI
   * @param {CUSTOMER_RESOURCE_INFO} customerInfo: customerInfo response from Api
   * @returns any[]
   */

  transformResourceInfoToOptionsArray(
    customerInfo: CUSTOMER_RESOURCE_INFO[]
  ): any[] {
    const optionsArray: any[] = [];
    customerInfo.forEach((info) => {
      optionsArray.push({
        value: JSON.stringify(info),
        label: `${info.customerName}/${info.customerCode}/${info.resourceName}`,
      });
    });
    return optionsArray;
  },

  /**
   * Transform the reference data to the data format which we can use in the cascader component
   * @param {Object} data: original data from API
   * @param {number} treeLevel: the level of menu
   * @returns {Object} data: the formated data array, defaultValue: default value of cascader
   */
  transformReferenceData(data: Record<string, any>, treeLevel: number): any {
    const originDataArray =
      data.data.hybris_workspace_reference_data_type[0].data;
    const defaultValue: any = [];
    const index = 1;
    originDataArray.sort((a, b) => a.alt_sequence - b.alt_sequence);
    originDataArray.forEach((tier) => {
      if (tier.default_indicator) defaultValue[0] = tier.code;
      this.handleArrayItems(tier, defaultValue, index);
    });
    return {
      data: originDataArray,
      defaultValue: defaultValue.length === treeLevel ? defaultValue : [],
    };
  },
  /**
   * Assign param values from workitem param values to runbook definition object
   * @param {string} isParamPrepopulate: boolean flag for parameter prefilling
   * @param {Object} paramJobDefinition: parameter info from runbook job definition
   * @returns {Object} paramWorkItem: parameter info from work item(hybris_workspace_item)
   */
  setParamValuesinParamOptionArray(
    isParamPrepopulate: string,
    paramJobDefinition: any,
    paramWorkItem: any
  ): any {
    if (isParamPrepopulate === "false") return paramJobDefinition;
    const runbookJobDefinitionParams = paramJobDefinition.map((option) => {
      if (option?.hidden === false || option?.hidden === undefined) {
        //Prefill values from workitem param option to param options in job definition
        const preFillParam =
          paramWorkItem[
            paramWorkItem.findIndex((op) => op.label === option.label)
          ];
        if (option.type === JOB_OPTION_TYPE.multiselect) {
          const multiselectVal = JSON.parse(preFillParam.value);
          multiselectVal.forEach((resource) => {
            option.value.push(JSON.stringify(resource));
          });
        } else if (option.type === JOB_OPTION_TYPE.date) {
          this.prefillDateOption(option, preFillParam);
        } else if (option.type === JOB_OPTION_TYPE.cascader) {
          const dTUVal = preFillParam.value;
          option.value = dTUVal.includes(",") ? dTUVal.split(",") : "";
        } else if (option.type === JOB_OPTION_TYPE.commerce) {
          option.value = preFillParam.value;
        } else if (option.type === JOB_OPTION_TYPE.secure) {
          option.value = "";
        } else {
          option.value = preFillParam.value;
        }
      } else {
        if (option._name === "CONSUMER_EMAIL") {
          option.value = store.state.userEmail;
        }
      }
      return option;
    });
    return runbookJobDefinitionParams;
  },

  prefillDateOption(option: any, preFillParam: any) {
    let dateVal;
    if (option.datetype === "datetimerange") {
      dateVal = preFillParam.value.includes("utc_timestamp")
        ? JSON.parse(preFillParam.value)
        : preFillParam.value.split(",");
    } else {
      dateVal = JSON.parse(preFillParam.value);
    }
    if (option?.timeZone) {
      option.timeZone = dateVal.timezone;
      let timeStamp = dateVal.utc_timestamp;
      timeStamp = timeStamp?.includes(",") ? timeStamp.split(",") : timeStamp;
      if (Array.isArray(timeStamp)) {
        const timeStampArr: any[] = [];
        timeStamp.forEach((val) => {
          const timeStampForTz = TimeUtil.convertUTCToTimeZoneValues(
            val,
            option.timeZone
          );
          timeStampArr.push(timeStampForTz);
        });
        option.value = timeStampArr;
      } else {
        const timeStampForTz = TimeUtil.convertUTCToTimeZoneValues(
          timeStamp,
          option.timeZone
        );
        option.value = timeStampForTz;
      }
    } else option.value = dateVal;
  },
  setDateValue(option: STEP_OPTION_ITEM_CONFIG): any {
    const value = option._value;
    let dateValue = value ? value : "";
    if (dateValue.indexOf(",") > -1) {
      dateValue = dateValue.split(",");
      dateValue = [
        moment(dateValue[0]).valueOf(),
        moment(dateValue[1]).valueOf(),
      ];
      return dateValue;
    } else if (dateValue.length > 0) {
      // option._value also has default value for last days/hours.
      // If _value has default value for date/time, get UTC offseted value and subtract it with default value and set it as default time.
      const unitOfTime = option.dateFormat.includes("HH:mm:ss")
        ? "hours"
        : "days";
      const currentBrowserTime = moment();
      const utcOffsetTimeStamp = TimeUtil.getUTCOffsetTimeStamp();
      // Time stamp calculated based on runbook designer provided default offset value based upon option type
      // if runbook designer provided option is without timezone, consider current browserTime
      // if runbook designer provided option is with timezone, consider utcOffsetTimeStamp
      const timeStampOffset =
        option.timeZone === "UTC" ? utcOffsetTimeStamp : currentBrowserTime;

      //user modified timestamp calculated by subtracting the runbook designer provided default value from UTC/browser timezone
      const userModifiedTimestamp = moment(timeStampOffset).subtract(
        value,
        unitOfTime
      );
      if (
        option.datetype === "daterange" ||
        option.datetype === "datetimerange"
      ) {
        const newDateRange = [
          moment(userModifiedTimestamp).valueOf(),
          moment(timeStampOffset).valueOf(),
        ];
        return newDateRange;
      } else return userModifiedTimestamp.valueOf();
    } else {
      return moment(dateValue).valueOf();
    }
  },
};
