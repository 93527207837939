
import "./SchedulePopup.scss";
import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import Cron from "@/shared/components/Cron/dist";
import {
  SCHEDULE_FORM_DATA,
  SCHEDULE_FORM_TEMP_DATA,
  SCHEDULE_TYPE,
} from "@/shared/interface/newItem";

@Component({
  name: "SchedulePopupComponent",
  components: {
    Cron,
  },
})
export default class SchedulePopupComponent extends Vue {
  SCHEDULE_TYPE = SCHEDULE_TYPE;
  runCount = SCHEDULE_TYPE.once;
  cronError = "";
  timezoneSettings = {
    currentTimezone: "UTC",
    currentTimezoneOffset: "±00:00",
  };
  scheduleOnceForm: SCHEDULE_FORM_DATA = {
    startTime: "",
  };
  schedulePeriodicalForm: SCHEDULE_FORM_DATA = {
    cronExpression: "0 0 0 1/1 * ? *",
  };
  scheduleFormTempData: SCHEDULE_FORM_TEMP_DATA = {
    startTime: "",
  };
  buttonLoading = false;

  @Watch("scheduleFormTempData", { immediate: false, deep: true })
  onScheduleFormTempDataChange(val: SCHEDULE_FORM_TEMP_DATA): void {
    if (val.startTime) {
      this.scheduleOnceForm.startTime =
        moment.utc(val.startTime).format("YYYY-MM-DD HH:mm:ss Z") || "";
    }
  }

  /**
   * submit the scheduled WorkItem
   * @returns any
   */
  submitScheduleWorkItem(): any {
    if (this.runCount === SCHEDULE_TYPE.once) {
      if (!this.validateForm("scheduleOnceForm") || !this.validateStartTime()) {
        return false;
      }
    } else {
      if (
        !this.validateForm("schedulePeriodicalForm") ||
        !this.validateCron()
      ) {
        return false;
      }
    }
    this.buttonLoading = true;
    const data = {
      scheduleType: this.runCount,
      formData: this.transformDataBeforeSumit(),
    };
    this.$emit("submitScheduleWorkItem", data);
  }

  /**
   * Validate form before submit
   * @param {string} formName: the name of current form needs to be validated
   * @returns {boolean} validFlag: true —— pass validation, false —— fail validation
   */
  validateForm(formName: string): boolean {
    let validFlag = true;
    (this.$refs[formName] as any & { validate: () => boolean }).validate(
      (valid) => {
        if (!valid) {
          validFlag = false;
        }
      }
    );
    return validFlag;
  }

  /**
   *  Validate if the start time is in 15 minutes
   * @returns {boolean} validFlag: true —— the start time is later than 15 mintues from now, false —— the start time is earlier than 15 mintues.
   */
  validateStartTime(): boolean {
    let validFlag = true;
    const now = new Date();
    const targetTime = new Date(this.scheduleFormTempData.startTime);
    const offset = targetTime.getTime() - (now.getTime() + 15 * 60 * 1000);
    if (offset < 0) {
      validFlag = false;
      this.$message.error(
        "please select a future time later than 15 minutes from now."
      );
    }
    return validFlag;
  }

  /**
   *  Validate if the Cron expression interval is bigger than 5 minutes
   * @returns {boolean} true —— validate success, false —— validate failed.
   */
  validateCron(): boolean {
    const expression = this.schedulePeriodicalForm.cronExpression;
    const cronArray = expression?.split(" ") || [];
    const secondsPattern = /^(([0-9*?]{1,}[/,-][0-9*?]{1,}.*)|(\*))$/;
    const minutesPattern =
      /^(([0-9]{1,2}\/[0-4]{1})|(\*)|([0-9*]{1,}[-]{1}[0-9*]{1,}))$/;
    const additionalValuePattern = /^.{1,},.{1,}$/;
    if (this.cronError !== "" && this.cronError !== undefined) {
      this.$message.error("Please set correct cron interval");
      return false;
    }
    if (cronArray[0] && cronArray[0].match(secondsPattern)) {
      this.$message.error(
        "Please set the execution interval no shorter than 5 minutes."
      );
      return false;
    }
    if (cronArray[1] && cronArray[1].match(minutesPattern)) {
      this.$message.error(
        "Please set the execution interval no shorter than 5 minutes."
      );
      return false;
    } else if (cronArray[1] && cronArray[1].match(additionalValuePattern)) {
      const additionalValues = cronArray[1].split(",").map(Number) || [];
      additionalValues.sort((a, b) => a - b);
      for (let i = 1; i < additionalValues.length; i++) {
        if (Math.abs(additionalValues[i] - additionalValues[i - 1]) < 5) {
          this.$message.error(
            "Please set the execution interval no shorter than 5 minutes."
          );
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Transform the form data
   * for schedule once workitem, need to change the startTime to timestamp with timezone
   * @returns SCHEDULE_FORM_DATA
   */
  transformDataBeforeSumit(): SCHEDULE_FORM_DATA {
    let newFormData: SCHEDULE_FORM_DATA;
    if (this.runCount === SCHEDULE_TYPE.once) {
      const date = moment.utc(this.scheduleOnceForm.startTime).format();
      newFormData = {
        startTime: date,
      };
    } else {
      newFormData = {
        cronExpression: this.schedulePeriodicalForm.cronExpression,
      };
    }
    return newFormData;
  }
  /**
   * close the Pop-up
   * @returns void
   */
  closePopup(): void {
    this.$emit("closePopup");
  }

  /**
   * set the value of cron expression
   * @param {string} val: current select cron expression
   * @returns void
   */
  cronValueChanged(val: string, error): void {
    this.cronError = error;
    this.schedulePeriodicalForm.cronExpression = val;
  }
}
